import { getGlobalParameters } from '../../globalParametersService';
import { OrchestratorStore } from '../store';

export default function mediaContextEffects(
  orchestratorStore: OrchestratorStore,
) {
  const emitIsDeviceMobile = () => {
    const {
      tagConfig,
      visitor: {
        device: { deviceType },
      },
    } = getGlobalParameters();
    const isSandboxMode = tagConfig?.mode === 'sandboxed';

    orchestratorStore.dispatch(
      'isDeviceMobile',
      deviceType === 'mobile' || isSandboxMode,
    );
  };

  //_________________________________________________________________
  orchestratorStore.on('chatboxInitialized', emitIsDeviceMobile);
  //________________________________________________________________
  orchestratorStore.on('chatboxState', emitIsDeviceMobile);
  orchestratorStore.on('videoboxState', emitIsDeviceMobile);
}
