import { ChatboxThemeOverride } from '../../shared/themeOverride';
import { MAX_CHATBOX_ZINDEX } from '../../shared/constants';
import {
  ChatboxTemplateAttributes,
  CHAT_ICON_FAMILY,
} from '../../shared/types/chatboxTemplate';
import { NotificationPosition, ChatboxPosition } from './externalTypes';
import {
  ChatboxConfigurationWithoutState,
  Theme,
  ChatboxConfiguration,
  UpperCaseSupportedLanguages,
} from './types';
import { FeatureFlags } from '../../shared/types/dynamicConfig';

const isNil = (value: unknown) => value === undefined || value === null;
const getBooleanOrElse = (value: boolean, defaultValue: boolean) =>
  isNil(value) ? defaultValue : value;

const convertAnchorPosition = (
  anchorPosition?: ChatboxTemplateAttributes['position']['anchorPosition'],
): ChatboxPosition => {
  return !anchorPosition || anchorPosition === 'CORNER_BOTTOM_RIGHT'
    ? 'right'
    : 'left';
};

const getMobilePosition = ({ mobilePosition }: ChatboxTemplateAttributes) => {
  if (mobilePosition) {
    return {
      mobileOffsetX: mobilePosition.offsetX,
      mobileOffsetY: mobilePosition.offsetY,
      mobileDefaultChatboxPosition: convertAnchorPosition(
        mobilePosition.anchorPosition,
      ),
    };
  }

  return {
    mobileOffsetX: 20,
    mobileOffsetY: 20,
    mobileDefaultChatboxPosition: 'right' as ChatboxPosition,
  };
};

//______________________________________________________________________________________________
const googleFonts = [
  'Open Sans',
  'Source Sans Pro',
  'Montserrat',
  'Lato',
  'Nunito',
  'PT Serif',
  'Roboto',
  'EB Garamond',
  //____________________________________________________________________
];

const getFontUrlFromFontFamily = (fontFamily: string) =>
  googleFonts.includes(fontFamily)
    ? encodeURI(
        `https://static.iadvize.com/ui/fonts/google-fonts/${fontFamily}.css`,
      )
    : '';

export const buildTheme = (
  chatboxTemplateAttributes: ChatboxTemplateAttributes,
  themeOverride?: ChatboxThemeOverride,
): Theme => {
  const {
    fontFamily,
    fontUrl,
    primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,
    reducedIcon,
    isReducedIconDark,
    position: { offsetX, offsetY, anchorPosition },
    isMobilePositionEnabled,
    reducedSize,
    reducedRadius,
    zIndex,
  } = chatboxTemplateAttributes;

  const chatboxZIndex = Math.min(zIndex, MAX_CHATBOX_ZINDEX);

  return {
    fontFamily,
    primaryColor: themeOverride?.primaryColor || primaryColor,
    secondaryColor,
    primaryTextColor,
    secondaryTextColor,

    agentPrimaryColor: themeOverride?.agentPrimaryColor || '#EEEFF0',
    agentPrimaryTextColor: themeOverride?.agentPrimaryTextColor || '#3E4146',
    fontUrl: fontUrl || getFontUrlFromFontFamily(fontFamily),

    reducedIcon: reducedIcon || CHAT_ICON_FAMILY.FAMILY1,
    isReducedIconDark,

    offsetX,
    offsetY,
    defaultChatboxPosition: convertAnchorPosition(anchorPosition),
    isMobilePositionEnabled,
    reducedSize,
    reducedRadius,
    ...getMobilePosition(chatboxTemplateAttributes),
    chatboxZIndex,
  };
};

const buildConfiguration = (
  notificationPosition: NotificationPosition,
  chatboxTemplateAttributes: ChatboxTemplateAttributes,
  featureFlags: FeatureFlags,
): Omit<ChatboxConfiguration, 'state' | 'theme'> => {
  const {
    isUserAllowedToUploadImagesAndPDF,
    isUserAllowedToSeeHistory,
    isBrandingShown,
    profileZoneAttributes,
    allowVisitorCamera,
    disableVisitorCameraByDefault,
    avatars,
  } = chatboxTemplateAttributes;

  return {
    notificationPosition,
    shouldShowHistory: getBooleanOrElse(isUserAllowedToSeeHistory, true),
    uploadEnabled: getBooleanOrElse(isUserAllowedToUploadImagesAndPDF, true),
    shouldShowBranding: getBooleanOrElse(isBrandingShown, true),
    emojiEnabled: featureFlags['iadvize.conversations.livechat.emojiPicker'],
    profileZoneAttributes,
    avatars,
    allowVisitorCamera: getBooleanOrElse(allowVisitorCamera, true),
    disableVisitorCameraByDefault: getBooleanOrElse(
      disableVisitorCameraByDefault,
      false,
    ),
  };
};

export const createChatboxConfiguration = (
  notificationPosition: NotificationPosition,
  chatboxTemplateAttributes: ChatboxTemplateAttributes,
  featureFlags: FeatureFlags,
  themeOverride?: Partial<ChatboxThemeOverride>,
): ChatboxConfigurationWithoutState => {
  const configuration = buildConfiguration(
    notificationPosition,
    chatboxTemplateAttributes,
    featureFlags,
  );

  return {
    theme: buildTheme(chatboxTemplateAttributes, themeOverride),
    ...configuration,
  };
};

export const chatboxTemplateToTranslations = (
  chatboxTemplateAttributes: ChatboxTemplateAttributes,
  language: UpperCaseSupportedLanguages,
) => {
  const welcomeMessage: string =
    chatboxTemplateAttributes.welcomeMessage.text[language];
  const unavailabilityMessage: string =
    chatboxTemplateAttributes.unavailabilityMessage.text[language];
  const satisfactionMessage: string =
    chatboxTemplateAttributes.satisfactionMessage.text[language];
  const headerMessage: string =
    chatboxTemplateAttributes.headerMessage.text[language];
  const compositionAreaMessage: string =
    chatboxTemplateAttributes.compositionAreaMessage.text[language];

  const headerOfficeHoursCustomMessage: string =
    chatboxTemplateAttributes.profileZoneAttributes.beforeConversation
      .customOfficeHoursInfosMessage.text[language];
  const headerOutofOfficeHoursCustomMessage: string =
    chatboxTemplateAttributes.profileZoneAttributes.beforeConversation
      .customOutOfOfficeHoursInfosMessage.text[language];

  const headerBotDescriptionMessage: string =
    chatboxTemplateAttributes.profileZoneAttributes.duringConversation
      .participantPresentationMessage.bot.text[language];
  const headerExpertDescriptionMessage: string =
    chatboxTemplateAttributes.profileZoneAttributes.duringConversation
      .participantPresentationMessage.expert.text[language];
  const headerOperatorDescriptionMessage: string =
    chatboxTemplateAttributes.profileZoneAttributes.duringConversation
      .participantPresentationMessage.operator.text[language];

  const translations: Record<string, string> = {};

  if (welcomeMessage) {
    translations['livechat.chatbox.conversation.welcomingMessage'] =
      welcomeMessage;
  }
  if (unavailabilityMessage) {
    translations['livechat.chatbox.conversation.unavailabilityMessage'] =
      unavailabilityMessage;
  }
  if (satisfactionMessage) {
    translations['livechat.chatbox.conversation.satisfactionMessage'] =
      satisfactionMessage;
  }
  if (headerMessage) {
    translations['chatbox.header.title'] = headerMessage;
  }
  if (compositionAreaMessage) {
    translations['chatbox.composezone.placeholder'] = compositionAreaMessage;
  }

  translations['chatbox.header.officeHoursCustomMessage'] =
    headerOfficeHoursCustomMessage || '';

  translations['chatbox.header.outOfOfficeHoursCustomMessage'] =
    headerOutofOfficeHoursCustomMessage || '';

  translations['chatbox.header.botDescriptionMessage'] =
    headerBotDescriptionMessage || '';

  translations['chatbox.header.expertDescriptionMessage'] =
    headerExpertDescriptionMessage || '';

  translations['chatbox.header.operatorDescriptionMessage'] =
    headerOperatorDescriptionMessage || '';

  return translations;
};
