import { publicReduceChatbox } from '../actions';
import { OrchestratorStore } from '../store';

export default function mobileClickOutsideEffects(
  orchestratorStore: OrchestratorStore,
) {
  const emit = () => {
    const state = orchestratorStore.getState();

    if (state.chatboxState === 'OPEN' && state.isDeviceMobile) {
      orchestratorStore.dispatch(publicReduceChatbox);
    }
  };
  window.parent.document.addEventListener('click', emit);
}
