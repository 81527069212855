import { UUID } from '../../../chatbox/app-helpers';
import { UIAgentAuthor } from '../../../chatbox/entities/Conversation';

import { ConversationMessage } from '../../../chatbox/livechat-helpers/rfcConversation/Conversation';
import {
  EscalationReason,
  EscalationType,
} from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { SurveyEvent } from '../../../chatbox/src/tracking/type';
import {
  ConversationsChatboxBaseEvent,
  EngagementVisitorGdprConsentType,
} from '../../../dataPipeline/types';
import { GenerativeAIFeedback } from '../../../shared/types/generativeAi';

export const publicChatboxClosed = '@@public/event/CHATBOX_CLOSED';
export const publicChatboxOpened = '@@public/event/CHATBOX_OPENED';
export const publicChatboxReduced = '@@public/event/CHATBOX_REDUCED';
export const publicCobrowsingAccepted = '@@public/event/COBROWSING_ACCEPTED';
export const publicCobrowsingRefused = '@@public/event/COBROWSING_REFUSED';
export const publicEscalationAccepted = '@@public/event/ESCALATION_ACCEPTED';
export const publicEscalationRefused = '@@public/event/ESCALATION_REFUSED';
export const publicFeedbackAnswered = '@@public/event/FEEDBACK_ANSWERED';
export const publicHungUp = '@@public/event/HUNG_UP';
export const publicMessageComposed = '@@public/event/MESSAGE_COMPOSED';
export const publicMessageSubmitted = '@@public/event/MESSAGE_SUBMITTED';
export const publicGdprRefused = '@@public/event/GDPR_REFUSED';
export const publicGdprAccepted = '@@public/event/GDPR_ACCEPTED';
export const publicGdprDisplayed = '@@public/event/GDPR_DISPLAYED';

export const publicNetworkStatusChanged =
  '@@public/event/NETWORK_STATUS_CHANGED';
export const publicTabVisibilityChanged =
  '@@public/event/TAB_VISIBILITY_CHANGED';
export const publicOperatorMissedVideoConversation =
  '@@public/action/PUBLIC_OPERATOR_MISSED_VIDEO_CONVERSATION';
export const publicOperatorPickedUp =
  '@@public/action/PUBLIC_OPERATOR_PICKED_UP';
export const publicPickedUp = '@@public/event/PICKED_UP';
export const publicProactiveMessagesFetched =
  '@@public/event/PROACTIVE_MESSAGES_FETCHED';
export const publicSurveyClosed = '@@public/event/SURVEY_CLOSED';
export const publicSurveyEventEmitted = '@@public/event/SURVEY_EVENT_EMITTED';
export const publicTrackingEventEmitted =
  '@@public/event/TRACKING_EVENT_EMITTED';
export const publicUploadFileReady = '@@public/event/UPLOAD_FILE_READY';
export const publicVideoboxOpened = '@@public/event/VIDEOBOX_OPENED';
export const publicVideoboxReduced = '@@public/event/VIDEOBOX_REDUCED';
export const publicVisitorStartedTyping =
  '@@public/event/VISITOR_STARTED_TYPING';
export const publicVisitorStoppedTyping =
  '@@public/event/VISITOR_STOPPED_TYPING';
export const publicSetHeaderAgent = '@@public/event/SET_HEADER_AGENT';

export type OrchestratorStoreEvents = {
  [publicChatboxClosed]: void;
  [publicChatboxOpened]: void;
  [publicChatboxReduced]: void;
  [publicCobrowsingAccepted]: void;
  [publicCobrowsingRefused]: void;
  [publicEscalationAccepted]: { correlationId: UUID; type: EscalationType };
  [publicEscalationRefused]: {
    correlationId: UUID;
    type: EscalationType;
    reason: EscalationReason;
  };
  [publicFeedbackAnswered]: {
    messageId: UUID;
    feedback: GenerativeAIFeedback;
  };
  [publicHungUp]: { correlationId: UUID; type: EscalationType };
  [publicMessageComposed]: { message: string };
  [publicMessageSubmitted]: { message: string; id?: string };
  [publicGdprRefused]: {
    consentType: EngagementVisitorGdprConsentType;
  };
  [publicGdprAccepted]: {
    consentType: EngagementVisitorGdprConsentType;
  };
  [publicGdprDisplayed]: {
    consentType: EngagementVisitorGdprConsentType;
  };
  [publicNetworkStatusChanged]: { online: boolean };
  [publicTabVisibilityChanged]: { visible: boolean };
  [publicOperatorMissedVideoConversation]: void;
  [publicOperatorPickedUp]: void;
  [publicPickedUp]: { correlationId: UUID; type: EscalationType };
  [publicProactiveMessagesFetched]: ConversationMessage[];
  [publicSurveyClosed]: void;
  [publicSurveyEventEmitted]: SurveyEvent;
  [publicTrackingEventEmitted]: ConversationsChatboxBaseEvent;
  [publicUploadFileReady]: File;
  [publicVideoboxOpened]: void;
  [publicVideoboxReduced]: void;
  [publicVisitorStartedTyping]: void;
  [publicVisitorStoppedTyping]: void;
  [publicSetHeaderAgent]: { agent: UIAgentAuthor };
};
