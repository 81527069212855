import { UUID } from '../../app-helpers';

export enum MessageConversationType {
  MESSAGE = 'MESSAGE',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
}

/*__________________________________________________________________*/

export enum ConversationType {
  CHAT = 'CHAT',
  VIDEO = 'VIDEO',
  CALL = 'CALL',
  UNKNOWN = 'UNKNOWN',
}

export enum EscalationType {
  VIDEO = 'VIDEO',
  VOICE = 'VOICE',
}

export enum HasAnswered {
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
  UNANSWERED = 'UNANSWERED',
}

export enum EscalationReason {
  DECLINED = 'DECLINED',
  HUNGUP = 'HUNGUP',
}

export enum AuthorType {
  UNKNOWN = 'UNKNOWN',
  VISITOR = 'VISITOR',
  ADMIN = 'ADMIN',
  BOT = 'BOT',
  CTY_EXPERT = 'CTY_EXPERT',
  IBBU_EXPERT = 'IBBU_EXPERT',
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
  PROFILE = 'PROFILE',

  /*________________________________________________________________________________________________________________________________________________________________________________________*/
  EXPERT = 'EXPERT',
}

export type WaitingTimeEstimation = 'DATE' | 'DELAY' | 'EXCEEDED' | 'UNDEFINED';

export type UnknownMessageAuthor = {
  type: AuthorType.UNKNOWN;
};

export type GeneralMessageAuthor = {
  nickName?: string;
  pictureUrl?: string;
  externalUrl?: string;
};

export type VisitorMessageAuthor = {
  id: string;
  type: AuthorType.VISITOR;
} & GeneralMessageAuthor;

export type AgentMessageAuthor = {
  id: string;
  type:
    | AuthorType.ADMIN
    | AuthorType.BOT
    | AuthorType.EXPERT //______________________________________________
    | AuthorType.CTY_EXPERT
    | AuthorType.IBBU_EXPERT
    | AuthorType.MANAGER
    | AuthorType.OPERATOR
    | AuthorType.UNKNOWN;
  nickName: string;
} & GeneralMessageAuthor;

export type ProfileMessageAuthor = {
  id: string;
  type: AuthorType.PROFILE;
  nickName: string;
} & GeneralMessageAuthor;

export type MessageAuthor =
  | UnknownMessageAuthor
  | VisitorMessageAuthor
  | AgentMessageAuthor
  | ProfileMessageAuthor;

export enum AttachmentType {
  IMAGE = 'IMAGE',
  PRODUCT = 'PRODUCT',
  FILE = 'FILE',
  LINK = 'LINK',
  SELECT = 'SELECT',
  OFFER = 'OFFER',
  QUICK_REPLY_MENU = 'QUICK_REPLY_MENU',
  CARD = 'CARD',
  CARD_BUNDLE = 'CARD_BUNDLE',
  PRODUCT_OFFER = 'PRODUCT_OFFER',
  PRODUCT_OFFER_BUNDLE = 'PRODUCT_OFFER_BUNDLE',
  ADD_TO_CART = 'ADD_TO_CART',
  UNSUPPORTED = 'UNSUPPORTED',
  ESCALATION_INVITATION_SENT = 'ESCALATION_INVITATION_SENT',
  ESCALATION_ACCEPTED = 'ESCALATION_ACCEPTED',
  ESCALATION_ENDED = 'ESCALATION_ENDED',
  HUNG_UP = 'HUNG_UP',
  FEEDBACK_REQUESTED = 'FEEDBACK_REQUESTED',
  FEEDBACK_SENT = 'FEEDBACK_SENT',
}

export type FeedbackSentAttachment = {
  type: AttachmentType.FEEDBACK_SENT;
  data: {
    messageId: UUID;
    feedback: string;
  };
};

export type FeedbackRequestedAttachement = {
  type: AttachmentType.FEEDBACK_REQUESTED;
  text: string;
  data: {
    reason: FeedbackReason;
  };
};

export type FeedbackReason = {
  GENERATIVE_AI_MESSAGE: 'GENERATIVE_AI_MESSAGE';
};

export type ImageAttachement = {
  type: AttachmentType.IMAGE;
  data: {
    fileName: string;
    mimeType: string;
    url: URL;
  };
};

export type ProductAttachement = {
  type: AttachmentType.PRODUCT;
  data: {
    title: string;
    productUrl: URL;
    isAvailable: boolean;
    imageUrl: URL;
    catalogPrice: string;
    promotionPrice: string;
  };
};

export type FileAttachement = {
  type: AttachmentType.FILE;
  data: {
    fileName: string;
    mimeType: string;
    url: URL;
  };
};

export type LinkAttachement = {
  type: AttachmentType.LINK;
  data: {
    url: URL;
    title: string;
  };
};

export type SelectAttachment = {
  type: AttachmentType.SELECT;
  data: {
    title: string;
  };
};

export type OfferAttachement = {
  type: AttachmentType.OFFER;
  data: {
    url: URL;
    title: string;
    description: string;
    imageUrl: URL;
  };
};

export type CardAttachment = {
  type: AttachmentType.CARD;
  data: {
    image?: {
      url: string;
      description: string;
    };
    title?: string;
    text?: string;
    actions: Action[];
  };
};

export type CardBundleAttachment = {
  type: AttachmentType.CARD_BUNDLE;
  data: {
    cards: CardAttachment[];
    title?: string;
    actionType?: ActionType;
  };
};

export type ProductOfferAttachment = {
  type: AttachmentType.PRODUCT_OFFER;
  data: {
    image?: {
      url: string;
      description: string;
    };
    name: string;
    price: string;
    offerPrice?: string;
    internalProductId?: string;
    availability?: ProductOfferAvailability;
    description?: string;
    actions: Action[];
  };
};

export type ProductOfferBundleAttachment = {
  type: AttachmentType.PRODUCT_OFFER_BUNDLE;
  data: {
    productOffers: ProductOfferAttachment[];
  };
};

export type QuickReplyMenuAttachement = {
  type: AttachmentType.QUICK_REPLY_MENU;
  data: {
    message: string;
    choices: string[];
  };
};

export type UnsupportedAttachement = {
  type: AttachmentType.UNSUPPORTED;
  data: {
    raw: string;
  };
};

export type EscalationInvitationSentAttachment = {
  type: AttachmentType.ESCALATION_INVITATION_SENT;
  data: {
    correlationId: UUID;
    type: EscalationType;
  };
};

export type EscalationAcceptedAttachment = {
  type: AttachmentType.ESCALATION_ACCEPTED;
  data: {
    correlationId: UUID;
    type: EscalationType;
  };
};

export type EscalationEndedAttachment = {
  type: AttachmentType.ESCALATION_ENDED;
  data: {
    correlationId: UUID;
    type: EscalationType;
    reason: EscalationReason;
  };
};

export type HungUpAttachment = {
  type: AttachmentType.HUNG_UP;
  data: {
    correlationId: UUID;
    type: ConversationType;
  };
};

export type EscalationAttachment =
  | EscalationInvitationSentAttachment
  | EscalationAcceptedAttachment
  | EscalationEndedAttachment;

export type MessageAttachment =
  | ImageAttachement
  | ProductAttachement
  | FileAttachement
  | LinkAttachement
  | SelectAttachment
  | OfferAttachement
  | CardAttachment
  | CardBundleAttachment
  | ProductOfferAttachment
  | ProductOfferBundleAttachment
  | QuickReplyMenuAttachement
  | UnsupportedAttachement
  | EscalationAttachment
  | HungUpAttachment
  | FeedbackRequestedAttachement
  | FeedbackSentAttachment;

export type Message = {
  id: string;
  type: MessageConversationType.MESSAGE;
  text: string;
  createdAt: string; //_____________________________
  author: MessageAuthor;
  sentAs: MessageAuthor;
  attachments: MessageAttachment[];
};

export type PrefetchedMessage = {
  type: MessageConversationType.MESSAGE;
  text?: string;
  author: MessageAuthor;
  attachments?: MessageAttachment;
};

export type EscalationInvitationMessage = {
  attachments: EscalationInvitationSentAttachment[];
} & Message;
export type EscalationAcceptedMessage = {
  attachments: EscalationAcceptedAttachment[];
} & Message;
export type EscalationEndedMessage = {
  attachments: EscalationEndedAttachment[];
} & Message;
export type EscalationMessage =
  | EscalationInvitationMessage
  | EscalationAcceptedMessage
  | EscalationEndedMessage;

export type FeedbackMessage = {
  attachments: FeedbackSentAttachment[];
} & Message;

export type QuickReplyMenuMessage = {
  attachments: QuickReplyMenuAttachement[];
} & Message;

export const createMockedOperatorMessage = (
  partialMessage: Partial<Message> = {},
): Message => {
  const author = {
    type: AuthorType.OPERATOR,
    id: 'id',
    nickName: 'nickName',
  };
  return {
    id: 'id',
    type: MessageConversationType.MESSAGE,
    text: 'Hello world',
    createdAt: new Date().toISOString(),
    author,
    sentAs: author,
    attachments: [],
    ...partialMessage,
  };
};

export type ProductOfferAvailability = {
  status: ProductOfferAvailabilityStatus;
};

export enum ProductOfferAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum ActionType {
  LINK = 'LINK',
  SELECT = 'SELECT',
}

export type GenericAction = {
  type: ActionType;
};

export type LinkAction = {
  type: ActionType.LINK;
  url: string;
  title: string;
} & GenericAction;

export type SelectAction = {
  type: ActionType.SELECT;
  title: string;
} & GenericAction;

export type Action = LinkAction | SelectAction;
