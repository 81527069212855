import { OrchestratorState } from './types';

export const isFullscreen = (state: OrchestratorState) => state.fullscreen;

export const isChatboxOpen = (state: OrchestratorState) =>
  state.chatboxState === 'OPEN';

export const isOpenedVideoAndChatbox = (state: OrchestratorState) =>
  state.videoboxState === 'OPEN' || state.chatboxState === 'OPEN';

export const isMobileOpenedVideoAndChatbox = (state: OrchestratorState) =>
  state.isDeviceMobile && isOpenedVideoAndChatbox(state);
