import { Translations } from '@iadvize/translations-library';
import { NotificationPosition } from './externalTypes';
import {
  createChatboxConfiguration,
  chatboxTemplateToTranslations,
} from './chatboxConfigurationHelpers';

import { DynamicConfig, FeatureFlags } from '../../shared/types/dynamicConfig';
import { Configuration, UpperCaseSupportedLanguages } from './types';
import { GetIsClickedUrlHandled } from '../../entry/publicMethods/handleClickedUrl/types';
import { TagConfig } from '../../entry/iAdvizeInterface/types';
import { IdzFlags } from '../../shared/types/IdzFlags';
import { ChatboxTemplateAttributes } from '../../shared/types/chatboxTemplate';
import * as CHAT_STATUS from '../../shared/types/chatStatus';

export const getConfiguration = (
  flags: IdzFlags,
  featureFlags: FeatureFlags,
  chatboxTemplateAttributes: ChatboxTemplateAttributes,
  getRawTranslations: () => Translations,
  notificationPosition: NotificationPosition,
  isFromMail: boolean,
  isAuthenticated: boolean,
  proactifData: DynamicConfig['proactifData'],
  getIsClickedUrlHandled: GetIsClickedUrlHandled,
  tagConfig?: TagConfig,
  isVideoLobbyEnabled?: boolean,
  videoLobbyGroupRepartition?: IdzFlags['videoLobbyGroupRepartition'],
): Configuration => {
  const historyId = Number(flags.history_id) === 0 ? null : flags.history_id;
  const conversationId =
    flags.conversationId === undefined ? null : flags.conversationId;
  const activeRuleId = flags.proactif_activatedId;
  return {
    historyId,
    isFromMail,
    conversationId,
    getTranslations: () => ({
      ...getRawTranslations(),
      ...chatboxTemplateToTranslations(
        chatboxTemplateAttributes,
        flags.curlang.toUpperCase() as UpperCaseSupportedLanguages,
      ),
    }),
    isChatting: flags.chat_status === `${CHAT_STATUS.CHATTING}`,
    language: flags.curlang,
    chatbox: {
      state: flags.chatbox_state,
      ...createChatboxConfiguration(
        notificationPosition,
        chatboxTemplateAttributes,
        featureFlags,
        tagConfig?.themeOverride,
      ),
    },
    featureFlags,
    isAuthenticated,
    activeRuleId,
    proactifData,
    getIsClickedUrlHandled,
    tagConfig,
    isVideoLobbyEnabled: !!isVideoLobbyEnabled,
    videoLobbyGroupRepartition: videoLobbyGroupRepartition || null,
  };
};
