import {
  ConversationType,
  MessageConversationType,
} from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { ConversationMessage } from '../../../chatbox/livechat-helpers/rfcConversation/Conversation';
import { OrchestratorState } from '../types';
import {
  UIMessageStatus,
  ConversationStatus,
  NotStartedChatConversation,
  NotStartedVideoConversation,
  OngoingVideoConversation,
  OngoingChatConversation,
  OngoingCallConversation,
} from '../../../chatbox/entities/Conversation';
import { OrchestratorStore } from './store';
import { ConversationLegacyId, UUID } from '../../../shared/types/utils';
import {
  OrchestratorStoreActions,
  publicAddThreadNotification,
  publicAddToCartMessage,
  publicAskStartConversation,
  publicDisableComposeZone,
  publicDisplayOperatorIsTypingIndicator,
  publicEnableComposeZone,
  publicEndConversation,
  publicFileUploadError,
  publicFileUploadProgress,
  publicFileUploadSuccess,
  publicFilterReceiveMessage,
  publicGDPRWorkflowDone,
  publicHideOperatorIsTypingIndicator,
  publicHistoryFetched,
  publicHoverNotification,
  publicNotifyWaitingIsListFull,
  publicOpenChatbox,
  publicOpenSettingsPanel,
  publicOpenVideobox,
  publicReceiveMessage,
  publicReduceChatbox,
  publicRemoveThreadNotification,
  publicResetChatbox,
  publicSendFirstVisitorMessage,
  publicSetAuthenticationState,
  publicSetCurrentOperator,
  publicSetNotificationEnabled,
  publicSetOperatorPresence,
  publicSetPendingMessagesAsErrored,
  publicSetUnreadMessageCount,
  publicShouldAnimate,
  publicStartConversation,
  publicUpdateLastSeenMessageDate,
  publicUpdateNotificationSettingsSuccess,
  publicXmppClosedWhileAway,
  publicXmppReconnected,
} from './actions';
import { StoreEventsFactory } from '../../../shared/utils/createStore';
import {
  OrchestratorStoreEvents,
  publicChatboxClosed,
  publicChatboxOpened,
  publicChatboxReduced,
  publicCobrowsingAccepted,
  publicCobrowsingRefused,
  publicEscalationAccepted,
  publicEscalationRefused,
  publicFeedbackAnswered,
  publicGdprAccepted,
  publicGdprDisplayed,
  publicGdprRefused,
  publicHungUp,
  publicMessageComposed,
  publicMessageSubmitted,
  publicNetworkStatusChanged,
  publicOperatorMissedVideoConversation,
  publicOperatorPickedUp,
  publicPickedUp,
  publicProactiveMessagesFetched,
  publicSetHeaderAgent,
  publicSurveyClosed,
  publicSurveyEventEmitted,
  publicTabVisibilityChanged,
  publicTrackingEventEmitted,
  publicUploadFileReady,
  publicVideoboxOpened,
  publicVideoboxReduced,
  publicVisitorStartedTyping,
  publicVisitorStoppedTyping,
} from './events';
import { CrossEventEmitterType } from '../../../entry/crossEventEmitter';

const isMessageUseful = (message: ConversationMessage) =>
  (message.type === MessageConversationType.MESSAGE && message.text) ||
  message.attachments.length;

export type OrchestratorChannel = ReturnType<typeof createOrchestratorChannel>;

export default function createOrchestratorChannel(
  { dispatch, getState, off, on }: OrchestratorStore,
  crossEventEmitter: CrossEventEmitterType,
) {
  function createListener<
    Action extends
      | keyof OrchestratorStoreActions
      | keyof OrchestratorStoreEvents,
  >(action: Action) {
    type Callback = StoreEventsFactory<
      OrchestratorState,
      OrchestratorStoreEvents & OrchestratorStoreActions
    >[Action];
    return (callback: Callback) => {
      const cb: Callback = (...args: unknown[]) => {
        //_____________________________
        if (args.length) callback(...args);
        //__________________________________________________________
        else callback({});
      };
      on(action, cb as Callback);
      return () => off(action, cb as Callback);
    };
  }

  function createPropertyChangeListener<
    Property extends keyof OrchestratorState,
  >(property: Property) {
    return (
      callback: StoreEventsFactory<
        OrchestratorState,
        OrchestratorStoreEvents & OrchestratorStoreActions
      >[Property],
    ) => {
      on(property, callback);
      return () => off(property, callback);
    };
  }

  function createPropertyDispatcher<
    Action extends
      | keyof OrchestratorState
      | keyof OrchestratorStoreActions
      | keyof OrchestratorStoreEvents,
  >(action: Action, additionalCallback?: Function) {
    return (
      ...payload: Parameters<
        //_________________________________________________________
        StoreEventsFactory<
          OrchestratorState,
          OrchestratorStoreEvents & OrchestratorStoreActions
        >[Action]
      >
    ) => {
      dispatch(action, ...payload);
      additionalCallback?.(...payload);
    };
  }

  function createDispatcher<
    Action extends
      | keyof OrchestratorState
      | keyof OrchestratorStoreActions
      | keyof OrchestratorStoreEvents,
  >(action: Action, additionalCallback?: Function) {
    return (
      ...payload: Parameters<
        //_________________________________________________________
        StoreEventsFactory<
          OrchestratorState,
          OrchestratorStoreEvents & OrchestratorStoreActions
        >[Action]
      >
    ) => {
      const areSagasInitialized = getState().chatboxSagasInitialized;
      const exec = () => {
        dispatch(action, ...payload);
        additionalCallback?.(...payload);
      };
      if (areSagasInitialized) {
        exec();
      } else {
        createPropertyChangeListener('chatboxSagasInitialized')(exec);
      }
    };
  }

  return {
    /*__________________________*/
    getOrchestratorState: (): OrchestratorState => getState(),
    isProactiveConversation: (): boolean => getState().isProactiveConversation,

    /*_______________________________*/

    initializeChatbox: createPropertyDispatcher(
      'chatboxInitializationParameters',
    ),
    onInitializeChatbox: createPropertyChangeListener(
      'chatboxInitializationParameters',
    ),

    setFirstVisitorMessage: createPropertyDispatcher('firstVisitorMessage'),

    setCurrentInputMode: createPropertyDispatcher('currentInputMode'),

    initializeSagas: createPropertyDispatcher('chatboxSagasInitialized'),
    onSagasInitialized: createPropertyChangeListener('chatboxSagasInitialized'),

    setNotificationPosition: createPropertyDispatcher('notificationPosition'),
    setNotificationTemplate: createPropertyDispatcher('notificationTemplate'),

    /*____________________________________________*/
    openChatbox: createDispatcher(publicOpenChatbox),
    onOpenChatbox: createListener(publicOpenChatbox),

    reduceChatbox: createDispatcher(publicReduceChatbox),
    onReduceChatbox: createListener(publicReduceChatbox),

    sendFirstVisitorMessage: createDispatcher(publicSendFirstVisitorMessage),
    onSendFirstVisitorMessage: createListener(publicSendFirstVisitorMessage),

    shouldAnimate: createDispatcher(publicShouldAnimate),
    onShouldAnimate: createListener(publicShouldAnimate),

    openVideobox: createDispatcher(publicOpenVideobox),
    onOpenVideobox: createListener(publicOpenVideobox),

    receiveMessage: (
      message: ConversationMessage,
      messageStatus: UIMessageStatus = UIMessageStatus.SENT,
    ) => {
      const state = getState();

      if (isMessageUseful(message)) {
        if (state.prefetchedMessagesCount > 0) {
          dispatch(publicFilterReceiveMessage, message);
        } else {
          dispatch(publicReceiveMessage, {
            message,
            messageStatus,
          });
        }
      }
    },

    onReceiveMessage: createListener(publicReceiveMessage),
    onFilterReceiveMessage: createListener(publicFilterReceiveMessage),

    setPendingMessagesAsErrored: createDispatcher(
      publicSetPendingMessagesAsErrored,
    ),
    onSetPendingMessagesAsErrored: createListener(
      publicSetPendingMessagesAsErrored,
    ),

    displayOperatorIsTypingIndicator: createDispatcher(
      publicDisplayOperatorIsTypingIndicator,
    ),
    onDisplayOperatorIsTypingIndicator: createListener(
      publicDisplayOperatorIsTypingIndicator,
    ),

    hideOperatorIsTypingIndicator: createDispatcher(
      publicHideOperatorIsTypingIndicator,
    ),
    onHideOperatorIsTypingIndicator: createListener(
      publicHideOperatorIsTypingIndicator,
    ),

    setCurrentOperator: createDispatcher(publicSetCurrentOperator, () =>
      crossEventEmitter.emit('set-current-operator', crossEventEmitter.uuid),
    ),
    onSetCurrentOperator: createListener(publicSetCurrentOperator),

    endConversation: createDispatcher(publicEndConversation),
    onEndConversation: createListener(publicEndConversation),

    resetChatbox: createDispatcher(publicResetChatbox),
    onResetChatbox: createListener(publicResetChatbox),

    addToCartMessage: createDispatcher(publicAddToCartMessage),
    onAddToCartMessage: createListener(publicAddToCartMessage),

    addThreadNotification: createDispatcher(publicAddThreadNotification),
    onAddThreadNotification: createListener(publicAddThreadNotification),

    removeThreadNotification: createDispatcher(publicRemoveThreadNotification),
    onRemoveThreadNotification: createListener(publicRemoveThreadNotification),

    enableComposeZone: createDispatcher(publicEnableComposeZone),
    onEnableComposeZone: createListener(publicEnableComposeZone),

    disableComposeZone: createDispatcher(publicDisableComposeZone),
    onDisableComposeZone: createListener(publicDisableComposeZone),

    hoverNotification: createDispatcher(publicHoverNotification),
    onHoverNotification: createListener(publicHoverNotification),

    startConversation: createDispatcher(publicStartConversation, () =>
      crossEventEmitter.emit('conversation-started', crossEventEmitter.uuid),
    ),
    onStartConversation: createListener(publicStartConversation),

    askStartConversation: createDispatcher(publicAskStartConversation),
    onAskStartConversation: createListener(publicAskStartConversation),

    notifyWaitingIsListFull: createDispatcher(publicNotifyWaitingIsListFull),
    onNotifyWaitingIsListFull: createListener(publicNotifyWaitingIsListFull),

    fileUploadProgress: createDispatcher(publicFileUploadProgress),
    onFileUploadProgress: createListener(publicFileUploadProgress),

    fileUploadSuccess: createDispatcher(publicFileUploadSuccess),
    onFileUploadSuccess: createListener(publicFileUploadSuccess),

    fileUploadError: createDispatcher(publicFileUploadError),
    onFileUploadError: createListener(publicFileUploadError),

    openSettingsPanel: createDispatcher(publicOpenSettingsPanel),
    onOpenSettingsPanel: createListener(publicOpenSettingsPanel),

    setNotificationEnabled: createDispatcher(publicSetNotificationEnabled),
    onNotificationEnabledSet: createListener(publicSetNotificationEnabled),

    setOperatorPresence: createDispatcher(publicSetOperatorPresence),
    onSetOperatorPresence: createListener(publicSetOperatorPresence),

    setAuthenticationState: createDispatcher(publicSetAuthenticationState),
    onSetAuthenticationState: createListener(publicSetAuthenticationState),

    setUnreadMessageCount: (unreadMessageCount: number) => {
      dispatch(publicSetUnreadMessageCount, unreadMessageCount);
      if (unreadMessageCount === 0) {
        crossEventEmitter.emit(
          'last-seen-message-date-updated',
          crossEventEmitter.uuid,
        );
      }
    },
    onSetUnreadMessageCount: createListener(publicSetUnreadMessageCount),

    updateLastSeenMessageDate: createDispatcher(
      publicUpdateLastSeenMessageDate,
    ),
    onUpdatepLastSeenMessageDate: createListener(
      publicUpdateLastSeenMessageDate,
    ),

    updateNotificationSettingsSuccess: createDispatcher(
      publicUpdateNotificationSettingsSuccess,
    ),
    onUpdateNotificationSettingsSuccess: createListener(
      publicUpdateNotificationSettingsSuccess,
    ),

    historyFetched: createDispatcher(publicHistoryFetched),
    onHistoryFetched: createListener(publicHistoryFetched),

    xmppReconnected: createDispatcher(publicXmppReconnected),
    onXmppReconnected: createListener(publicXmppReconnected),

    xmppClosedWhileAway: createDispatcher(publicXmppClosedWhileAway),
    onXmppClosedWhileAway: createListener(publicXmppClosedWhileAway),

    gdprWorkflowDone: createDispatcher(publicGDPRWorkflowDone),
    onGDPRWorkflowDone: createListener(publicGDPRWorkflowDone),

    /*___________________________________________*/

    chatboxOpened: createDispatcher(publicChatboxOpened),
    onChatboxOpened: createListener(publicChatboxOpened),

    chatboxReduced: createDispatcher(publicChatboxReduced),
    onChatboxReduced: createListener(publicChatboxReduced),

    //__________________________________________________________________
    chatboxClosed: createPropertyDispatcher(publicChatboxClosed),
    onChatboxClosed: createListener(publicChatboxClosed),

    videoboxOpened: createDispatcher(publicVideoboxOpened),

    videoboxReduced: createDispatcher(publicVideoboxReduced),

    messageComposed: createDispatcher(publicMessageComposed),
    onMessageComposed: createListener(publicMessageComposed),

    messageSubmitted: createDispatcher(publicMessageSubmitted),
    onMessageSubmitted: createListener(publicMessageSubmitted),

    gdprRefused: createDispatcher(publicGdprRefused),
    onGdprRefused: createListener(publicGdprRefused),

    gdprAccepted: createDispatcher(publicGdprAccepted),
    onGdprAccepted: createListener(publicGdprAccepted),

    gdprDisplayed: createDispatcher(publicGdprDisplayed),
    onGdprDisplayed: createListener(publicGdprDisplayed),

    uploadFileReady: createDispatcher(publicUploadFileReady),
    onFileUploadReady: createListener(publicUploadFileReady),

    cobrowsingAccepted: createDispatcher(publicCobrowsingAccepted),
    onCobrowsingAccepted: createListener(publicCobrowsingAccepted),

    cobrowsingRefused: createDispatcher(publicCobrowsingRefused),
    onCobrowsingRefused: createListener(publicCobrowsingRefused),

    escalationAccepted: createDispatcher(publicEscalationAccepted),
    onEscalationAccepted: createListener(publicEscalationAccepted),

    escalationRefused: createDispatcher(publicEscalationRefused),
    onEscalationRefused: createListener(publicEscalationRefused),

    feedbackAnswered: createDispatcher(publicFeedbackAnswered),
    onFeedbackAnswered: createListener(publicFeedbackAnswered),

    pickedUp: createDispatcher(publicPickedUp),
    onPickedUp: createListener(publicPickedUp),

    hungUp: createDispatcher(publicHungUp),
    onHungUp: createListener(publicHungUp),

    visitorStartedTyping: createDispatcher(publicVisitorStartedTyping),
    onVisitorStartedTyping: createListener(publicVisitorStartedTyping),

    visitorStoppedTyping: createDispatcher(publicVisitorStoppedTyping),
    onVisitorStoppedTyping: createListener(publicVisitorStoppedTyping),

    onNetworkStatusChanged: createListener(publicNetworkStatusChanged),

    onTabVisibilityChanged: createListener(publicTabVisibilityChanged),

    proactiveMessagesFetched: createDispatcher(publicProactiveMessagesFetched),
    onProactiveMessagesFetched: createListener(publicProactiveMessagesFetched),

    surveyClosed: createDispatcher(publicSurveyClosed),
    onSurveyClosed: createListener(publicSurveyClosed),

    trackingEventEmitted: createDispatcher(publicTrackingEventEmitted),
    onTrackingEventEmitted: createListener(publicTrackingEventEmitted),

    surveyEventEmitted: createDispatcher(publicSurveyEventEmitted),
    onSurveyEventEmitted: createListener(publicSurveyEventEmitted),

    operatorMissedVideoConversation: createDispatcher(
      publicOperatorMissedVideoConversation,
    ),
    onOperatorMissedVideoConversation: createListener(
      publicOperatorMissedVideoConversation,
    ),

    operatorPickedUp: createDispatcher(publicOperatorPickedUp),
    onOperatorPickedUp: createListener(publicOperatorPickedUp),

    setHeaderAgent: createDispatcher(publicSetHeaderAgent),
    onSetHeaderAgent: createListener(publicSetHeaderAgent),

    //______________________
    onCrossedTabConversationStarted(
      listener: (isLocalEmitter: boolean) => void,
    ) {
      crossEventEmitter.on('conversation-started', (uuid: string) =>
        listener(crossEventEmitter.isLocalEmitter(uuid)),
      );
    },

    onCrossedTabSetCurrentOperator(
      listener: (isLocalEmitter: boolean) => void,
    ) {
      crossEventEmitter.on('set-current-operator', (uuid: string) =>
        listener(crossEventEmitter.isLocalEmitter(uuid)),
      );
    },

    onCrossedTabLastSeenMessageDateUpdated(
      listener: (isLocalEmitter: boolean) => void,
    ) {
      crossEventEmitter.on('last-seen-message-date-updated', (uuid: string) =>
        listener(crossEventEmitter.isLocalEmitter(uuid)),
      );
    },

    initializeChatboxParametersHelper: {
      createNotStartedVideoConversation: (): NotStartedVideoConversation => ({
        status: ConversationStatus.NOT_STARTED,
        type: ConversationType.VIDEO,
      }),
      createNotStartedChatConversation: (
        isProactive = false,
      ): NotStartedChatConversation => ({
        isProactive,
        status: ConversationStatus.NOT_STARTED,
        type: ConversationType.CHAT,
      }),
      createOngoingVideoConversation: (
        chatId: ConversationLegacyId,
        conversationId: UUID,
        twilioKey: string,
      ): OngoingVideoConversation => ({
        chatId,
        conversationId,
        twilioKey,
        status: ConversationStatus.STARTED,
        type: ConversationType.VIDEO,
      }),
      createOngoingChatConversation: (
        chatId: ConversationLegacyId,
        conversationId: UUID,
      ): OngoingChatConversation => ({
        chatId,
        conversationId,
        status: ConversationStatus.STARTED,
        type: ConversationType.CHAT,
        isProactive: false,
      }),
      createOngoingCallConversation: (
        chatId: ConversationLegacyId,
        conversationId: UUID,
      ): OngoingCallConversation => ({
        chatId,
        conversationId,
        status: ConversationStatus.STARTED,
        type: ConversationType.CALL,
        isProactive: false,
      }),
    },
  };
}
