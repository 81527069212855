import { UUID } from './utils';

export enum CHAT_ICON_FAMILY {
  FAMILY1 = 'FAMILY1',
  FAMILY2 = 'FAMILY2',
  FAMILY3 = 'FAMILY3',
  FAMILY4 = 'FAMILY4',
  FAMILY5 = 'FAMILY5',
  FAMILY6 = 'FAMILY6',
}

export type ChatboxTemplateTextTranslations = {
  text: {
    AR: string;
    CA: string;
    CN: string;
    CZ: string;
    DE: string;
    DK: string;
    EL: string;
    EN: string;
    ES: string;
    EU: string;
    FR: string;
    HU: string;
    IT: string;
    JA: string;
    KO: string;
    NL: string;
    PL: string;
    PT: string;
    RO: string;
    RU: string;
    SE: string;
    TR: string;
    TW: string;
    UK: string;
  };
};

export type ProfileZoneAttributes = {
  beforeConversation: {
    template: 'NO_AVATAR' | 'ONE_AVATAR' | 'MANY_AVATARS';
    officeHoursInfosMessageType: 'MINUTES' | 'HOURS' | 'CUSTOM';
    outOfOfficeHoursInfosMessageType: 'HOURS' | 'CUSTOM';
    customOfficeHoursInfosMessage: ChatboxTemplateTextTranslations;
    customOutOfOfficeHoursInfosMessage: ChatboxTemplateTextTranslations;
    defaultAvatarUrls: string[];
  };
  duringConversation: {
    defaultAvatarUrl: string;
    isUsingDynamicAvatar: boolean;
    isIbbuBadgeEnabled: boolean;
    participantPresentationMessage: {
      bot: ChatboxTemplateTextTranslations;
      operator: ChatboxTemplateTextTranslations;
      expert: ChatboxTemplateTextTranslations;
    };
  };
};

export type AvatarAttributes = {
  beforeConversation: {
    template: 'NO_AVATAR' | 'ONE_AVATAR' | 'MANY_AVATARS';
    defaultAvatarUrls: string[];
  };
  duringConversation: {
    human: {
      isUsingDynamicAvatar: boolean;
      defaultAvatarUrl: string;
    };
    bot: {
      defaultAvatarUrl: string;
    };
    automatedFlow: {
      defaultAvatarUrl: string;
    };
  };
};

export type ChatboxTemplateAttributes = {
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  compositionAreaMessage: ChatboxTemplateTextTranslations;
  fontFamily: string;
  fontUrl: string;
  headerMessage: ChatboxTemplateTextTranslations;
  isUserAllowedToUploadImagesAndPDF: boolean;
  isUserAllowedToUseGIFs: boolean;
  satisfactionMessage: ChatboxTemplateTextTranslations;
  unavailabilityMessage: ChatboxTemplateTextTranslations;
  welcomeMessage: ChatboxTemplateTextTranslations;
  isUserAllowedToSeeHistory: boolean;
  isBrandingShown: boolean;
  reducedIcon: CHAT_ICON_FAMILY;
  isReducedIconDark: boolean;
  position: {
    anchorPosition?: 'CORNER_BOTTOM_RIGHT' | 'CORNER_BOTTOM_LEFT';
    offsetX: number;
    offsetY: number;
  };
  isMobilePositionEnabled: boolean;
  mobilePosition?: {
    anchorPosition?: 'CORNER_BOTTOM_RIGHT' | 'CORNER_BOTTOM_LEFT';
    offsetX: number;
    offsetY: number;
  };
  reducedSize: number;
  reducedRadius: number;
  zIndex: number;
  profileZoneAttributes: ProfileZoneAttributes;
  allowVisitorCamera: boolean;
  disableVisitorCameraByDefault: boolean;
  avatars: AvatarAttributes;
};

export type ChatboxTemplate = {
  id: UUID;
  templateAttributes: ChatboxTemplateAttributes;
};
