import { OrchestratorStore } from '../store';

import { getGlobalParameters } from '../../globalParametersService';
import { CHATBOX_STATE, NotificationPosition } from '../../externalTypes';
import { AppBoundaries } from '../../../../shared/types/appBoundaries';
import { isOpenedVideoAndChatbox } from '../../selectors';

const FULLSCREEN_WIDTH_BREAKPOINT = 768;
const CHATBOX_PADDING = 20;
const REDUCED_CHATBOX_PADDING = 15;
let isVideoConversation = false;

//_______________________________________________________
const existingViewportMeta =
  window.parent.document.head.querySelector<HTMLMetaElement>(
    '[name~=viewport][content]',
  );
const clientMetaContent = existingViewportMeta?.content || '';
const idzMetaContent =
  'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
const META_ID = 'idz-fullscreen-meta-viewport';

const removeAndRestoreMeta = () => {
  if (existingViewportMeta && existingViewportMeta.id === META_ID) {
    if (!clientMetaContent.length) {
      existingViewportMeta?.remove();
      return;
    }
    existingViewportMeta.removeAttribute('id');
    existingViewportMeta.content = clientMetaContent;
  }
};

const appendMeta = () => {
  removeAndRestoreMeta();
  if (existingViewportMeta) {
    existingViewportMeta.content +=
      (existingViewportMeta.content.length ? ',' : '') +
      idzMetaContent
        .split(',')
        .filter((meta) => !existingViewportMeta?.content.includes(meta))
        .join(',');
    existingViewportMeta.id = META_ID;
    return;
  }
  const meta = document.createElement('meta');
  meta.name = 'viewport';
  meta.content = idzMetaContent;
  meta.id = META_ID;
  window.parent.document.head.appendChild(meta);
};

const getIsBreakPointLimit = () => {
  const { tagConfig, getHost } = getGlobalParameters();
  const host = getHost();
  const isSandboxMode = tagConfig?.mode === 'sandboxed';

  return isSandboxMode
    ? !!host && host.width < FULLSCREEN_WIDTH_BREAKPOINT
    : window.parent?.matchMedia(`(max-width: ${FULLSCREEN_WIDTH_BREAKPOINT}px)`)
        .matches;
};

const getShouldBeFullscreen = () => {
  const { tagConfig } = getGlobalParameters();
  const isEmbeddedMode = tagConfig?.mode === 'embedded';

  return isEmbeddedMode || getIsBreakPointLimit();
};

const getChatboxPosition = () => {
  const { chatboxTemplate, visitor } = getGlobalParameters();
  const {
    position: desktopPosition,
    mobilePosition,
    isMobilePositionEnabled,
  } = chatboxTemplate.templateAttributes;
  const position =
    isMobilePositionEnabled &&
    mobilePosition !== undefined &&
    visitor.device.deviceType === 'mobile'
      ? mobilePosition
      : desktopPosition;

  return position;
};

const sendOpenedChatboxBoundaries = (
  notificationPosition: NotificationPosition,
) => {
  const { publicPropertiesStore, getHost } = getGlobalParameters();
  const position = getChatboxPosition();
  const host = getHost();

  if (getIsBreakPointLimit()) {
    publicPropertiesStore.dispatch('app:boundaries', {
      width: host?.width,
      height: host?.height,
      [notificationPosition]: 0,
      bottom: 0,
    } as unknown as AppBoundaries);
  } else {
    publicPropertiesStore.dispatch('app:boundaries', {
      width: 440,
      height: 690,
      [notificationPosition]: position.offsetX - CHATBOX_PADDING,
      bottom: position.offsetY - CHATBOX_PADDING,
    } as unknown as AppBoundaries);
  }
};

const sendReducedChatboxBoundaries = (
  notificationPosition: NotificationPosition,
) => {
  const { publicPropertiesStore } = getGlobalParameters();
  const position = getChatboxPosition();

  publicPropertiesStore.dispatch('app:boundaries', {
    width: isVideoConversation ? 210 : 100,
    height: 100,
    [notificationPosition]: position.offsetX - REDUCED_CHATBOX_PADDING,
    bottom: position.offsetY - REDUCED_CHATBOX_PADDING,
  } as unknown as AppBoundaries);
};

const emitFullscreenEvent = (
  orchestratorStore: OrchestratorStore,
  isFullScreen = getShouldBeFullscreen(),
) => {
  orchestratorStore.dispatch('fullscreen', isFullScreen);
};

function watchResizeEvents(orchestratorStore: OrchestratorStore) {
  window.parent.addEventListener('resize', () =>
    emitFullscreenEvent(orchestratorStore),
  );
  window.parent.addEventListener('load', () =>
    emitFullscreenEvent(orchestratorStore),
  );
}

function watchFullscreen(orchestratorStore: OrchestratorStore) {
  orchestratorStore.on('fullscreen', (isFullscreen) => {
    const isChatboxOpen = orchestratorStore.getState().chatboxState === 'OPEN';
    if (isChatboxOpen) {
      appendMeta();
    } else {
      removeAndRestoreMeta();
    }
    if (!isFullscreen) {
      removeAndRestoreMeta();
    }
  });
}

function watchChatboxState(orchestratorStore: OrchestratorStore) {
  let intervalId: number;
  const getHost = () => JSON.stringify(getGlobalParameters().getHost());
  let host = getHost();

  const onReducedChatbox = (notificationPosition: NotificationPosition) => {
    removeAndRestoreMeta();
    sendReducedChatboxBoundaries(notificationPosition);
  };

  const onOpenedChatbox = (notificationPosition: NotificationPosition) => {
    clearInterval(intervalId);
    sendOpenedChatboxBoundaries(notificationPosition);

    intervalId = window.setInterval(() => {
      const state = orchestratorStore.getState();
      if (!state.chatboxInitialized) {
        clearInterval(intervalId);
      }
      if (host !== getHost()) {
        host = getHost();
        sendOpenedChatboxBoundaries(notificationPosition);
      }
    }, 1000);
  };

  orchestratorStore.on('chatboxState', () => {
    const state = orchestratorStore.getState();
    const { chatboxState, notificationPosition } = state;

    if (chatboxState === ('OPEN' as CHATBOX_STATE)) {
      emitFullscreenEvent(orchestratorStore);
      onOpenedChatbox(notificationPosition);
    } else if (!isOpenedVideoAndChatbox(state)) {
      onReducedChatbox(notificationPosition);
    }
  });

  orchestratorStore.on('videoboxState', () => {
    const state = orchestratorStore.getState();
    const { videoboxState, notificationPosition } = state;
    isVideoConversation = true;

    if (videoboxState === 'OPEN') {
      emitFullscreenEvent(orchestratorStore);
      onOpenedChatbox(notificationPosition);
    } else if (!isOpenedVideoAndChatbox(state)) {
      onReducedChatbox(notificationPosition);
    }
  });
}

export default function fullscreenEffects(
  orchestratorStore: OrchestratorStore,
) {
  watchResizeEvents(orchestratorStore);
  watchFullscreen(orchestratorStore);
  watchChatboxState(orchestratorStore);
}
