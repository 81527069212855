import { publicTabVisibilityChanged } from '../events';
import { OrchestratorStore } from '../store';

export default function tabVisibilityEffects(
  orchestratorStore: OrchestratorStore,
) {
  window.addEventListener('visibilitychange', () =>
    orchestratorStore.dispatch(publicTabVisibilityChanged, {
      visible: !document.hidden,
    }),
  );
}
