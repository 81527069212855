import { ConversationMessage } from '../../../chatbox/livechat-helpers/rfcConversation/Conversation';
import {
  UIMessageStatus,
  SomeConversation,
  StartingConversation,
} from '../../../chatbox/entities/Conversation';

import { UINotification } from '../../../chatbox/entities/ThreadNotification';

import { AgentMessageAuthor } from '../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { AuthenticationState } from '../externalTypes';
import { UUID } from '../../../chatbox/app-helpers';
import { ConversationLegacyId } from '../../../shared/types/utils';

export const publicAddThreadNotification =
  '@@public/action/ADD_THREAD_NOTIFICATION';
export const publicAskStartConversation =
  '@@public/action/ASK_START_CONVERSATION';
export const publicDisableComposeZone = '@@public/action/DISABLE_COMPOSE_ZONE';
export const publicDisplayOperatorIsTypingIndicator =
  '@@public/action/DISPLAY_OPERATOR_IS_TYPING_INDICATOR';
export const publicEnableComposeZone = '@@public/action/ENABLE_COMPOSE_ZONE';
export const publicEndConversation = '@@public/action/END_CONVERSATION';
export const publicFileUploadError = '@@public/action/UPLOAD_FILE_ERROR';
export const publicFileUploadProgress = '@@public/action/UPLOAD_FILE_PROGRESS';
export const publicFileUploadSuccess = '@@public/action/UPLOAD_FILE_SUCCESS';
export const publicFilterReceiveMessage =
  '@@public/action/PUBLIC_FILTER_RECEIVE_MESSAGE';
export const publicGDPRWorkflowDone =
  '@@public/action/PUBLIC_GDPR_WORKFLOW_DONE';
export const publicHideOperatorIsTypingIndicator =
  '@@public/action/HIDE_OPERATOR_IS_TYPING_INDICATOR';
export const publicHistoryFetched = '@@public/event/HISTORY_FETCHED';
export const publicHoverNotification = '@@public/action/HOVER_NOTIFICATION';
export const publicNotifyWaitingIsListFull =
  '@@public/action/NOTIFY_WAITING_LIST_IS_FUL;';
export const publicOpenChatbox = '@@public/action/OPEN_CHATBOX';
export const publicReduceChatbox = '@@public/action/REDUCE_CHATBOX';
export const publicSendFirstVisitorMessage =
  '@@public/action/SEND_FIRST_VISITOR_MESSAGE';
export const publicOpenSettingsPanel =
  '@@public/action/PUBLIC_OPEN_SETTINGS_PANEL';
export const publicOpenVideobox = '@@public/action/OPEN_VIDEOBOX';
export const publicReceiveMessage = '@@public/action/RECEIVE_MESSAGE';
export const publicRemoveThreadNotification =
  '@@public/action/REMOVE_THREAD_NOTIFICATION';
export const publicResetChatbox = '@@public/action/PUBLIC_RESET_CHATBOX';
export const publicAddToCartMessage = '@@public/action/ADD_TO_CART_MESSAGE';
export const publicSetAuthenticationState =
  '@@public/event/SET_AUTHENTICATION_STATE';
export const publicSetCurrentOperator = '@@public/action/SET_CURRENT_OPERATOR';
export const publicSetNotificationEnabled =
  '@@public/action/PUBLIC_SET_NOTIFICATION_ENABLED';
export const publicSetOperatorPresence = '@@public/event/SET_OPERATOR_PRESENCE';
export const publicSetPendingMessagesAsErrored =
  '@@public/action/PUBLIC_SET_PENDING_MESSAGES_AS_ERRORED';
export const publicSetPrefetchedMessages =
  '@@public/action/SET_PREFETCHED_MESSAGES';
export const publicSetUnreadMessageCount =
  '@@public/event/SET_UNREAD_MESSAGE_COUNT';
export const publicShouldAnimate = '@@public/action/SHOULD_ANIMATE_CHATBOX';
export const publicStartConversation = '@@public/action/START_CONVERSATION';
export const publicUpdateLastSeenMessageDate =
  '@@public/event/UPDATE_LAST_SEEN_MESSAGE_DATE';
export const publicUpdateNotificationSettingsSuccess =
  '@@public/action/UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const publicXmppReconnected = '@@public/event/XMPP_RECONNECTED';
export const publicXmppClosedWhileAway =
  '@@public/event/XMPP_CLOSED_WHILE_AWAY';

export type OrchestratorStoreActions = {
  [publicAddThreadNotification]: UINotification;
  [publicAskStartConversation]: StartingConversation;
  [publicDisplayOperatorIsTypingIndicator]: void;
  [publicEnableComposeZone]: void;
  [publicDisableComposeZone]: void;
  [publicEndConversation]: { isSurveyDisabled: boolean };
  [publicFileUploadError]: { fileId: string };
  [publicFileUploadProgress]: { fileId: string; progress: number };
  [publicFileUploadSuccess]: { fileId: string };
  [publicFilterReceiveMessage]: ConversationMessage;
  [publicGDPRWorkflowDone]: void;
  [publicHoverNotification]: boolean;
  [publicHistoryFetched]: void;
  [publicHideOperatorIsTypingIndicator]: void;
  [publicNotifyWaitingIsListFull]: void;
  [publicOpenChatbox]: {
    shouldFocusComposeZone: boolean;
    shouldAnimateChatbox?: boolean;
  };
  [publicReduceChatbox]: void;
  [publicSendFirstVisitorMessage]: void;
  [publicOpenSettingsPanel]: void;
  [publicOpenVideobox]: void;
  [publicReceiveMessage]: {
    message: ConversationMessage;
    messageStatus: UIMessageStatus;
  };
  [publicRemoveThreadNotification]: { id: string };
  [publicResetChatbox]: { isUserIntended: boolean };
  [publicAddToCartMessage]: { productName: string; success: boolean };
  [publicSetAuthenticationState]: AuthenticationState;
  [publicSetCurrentOperator]: AgentMessageAuthor;
  [publicSetNotificationEnabled]: boolean;
  [publicSetOperatorPresence]: boolean;
  [publicSetPendingMessagesAsErrored]: void;
  [publicSetPrefetchedMessages]: { prefetchedMessagesCount: number };
  [publicSetUnreadMessageCount]: number;
  [publicShouldAnimate]: boolean;
  [publicStartConversation]: SomeConversation;
  [publicUpdateLastSeenMessageDate]: void;
  [publicUpdateNotificationSettingsSuccess]: {
    notificationEnabled: boolean;
    email: string;
    correlationId?: UUID;
  };
  [publicXmppReconnected]: void;
  [publicXmppClosedWhileAway]: {
    chatId: ConversationLegacyId;
    didMissConversationClosure: boolean;
  };
};
