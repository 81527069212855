import {
  DateISO,
  UUID,
  ConversationLegacyId,
  createFromType,
} from '../app-helpers';
import {
  AuthorType,
  ConversationType,
  EscalationReason,
  EscalationType,
  WaitingTimeEstimation,
} from '../livechat-helpers/rfcConversation/Messages';

import { ConversationMessage } from '../livechat-helpers/rfcConversation/Conversation';
import { GenerativeAIFeedback } from '../../shared/types/generativeAi';

export enum ConversationStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTING = 'STARTING',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
}

type AbstractConversation = {
  type: ConversationType;
  status: ConversationStatus;
};

type ChatConversation = {
  type: ConversationType.CHAT;
  isProactive: boolean;
} & AbstractConversation;

type VideoConversation = {
  type: ConversationType.VIDEO;
} & AbstractConversation;

type CallConversation = {
  type: ConversationType.CALL;
  isProactive: boolean;
} & AbstractConversation;

type AbstractNotStartedConversation = {
  status: ConversationStatus.NOT_STARTED;
} & AbstractConversation;

type AbstractStartingConversation = {
  status: ConversationStatus.STARTING;
} & AbstractConversation;

export type TextWithIdMessage = {
  message: string;
  id: string;
};
export type StartingChatConversation = ChatConversation &
  AbstractStartingConversation & {
    firstsVisitorMessages: TextWithIdMessage[];
    prefetchedMessages: ConversationMessage[];
  };

export type StartingVideoConversation = AbstractStartingConversation & {
  type: ConversationType.VIDEO;
};

export function createStartingChatConversation(
  firstsVisitorMessages: TextWithIdMessage[],
  prefetchedMessages: ConversationMessage[],
): StartingChatConversation {
  return {
    firstsVisitorMessages,
    prefetchedMessages,
    type: ConversationType.CHAT,
    status: ConversationStatus.STARTING,
    isProactive: Boolean(prefetchedMessages.length),
  };
}

export function createStartingVideoConversation(): StartingVideoConversation {
  return {
    type: ConversationType.VIDEO,
    status: ConversationStatus.STARTING,
  };
}

export type NotStartedChatConversation = ChatConversation &
  AbstractNotStartedConversation;
export type NotStartedVideoConversation = VideoConversation &
  AbstractNotStartedConversation;
export type NotStartedCallConversation = CallConversation &
  AbstractNotStartedConversation;
export type NotStartedConversation = NotStartedChatConversation &
  NotStartedVideoConversation &
  NotStartedCallConversation;

type AbstractOngoingConversation = {
  status: ConversationStatus.STARTED;
  chatId: ConversationLegacyId;
  conversationId: UUID;
} & AbstractConversation;

type AbstractEndedConversation = {
  status: ConversationStatus.ENDED;
  chatId: ConversationLegacyId;
  conversationId: UUID;
} & AbstractConversation;

export type OngoingChatConversation = ChatConversation &
  AbstractOngoingConversation;
export type OngoingVideoConversation = {
  twilioKey: string;
} & VideoConversation &
  AbstractOngoingConversation;
export type OngoingCallConversation = CallConversation &
  AbstractOngoingConversation;
export type OngoingConversation =
  | OngoingChatConversation
  | OngoingCallConversation
  | OngoingVideoConversation;

export type EndedChatConversation = ChatConversation &
  AbstractEndedConversation;
export type EndedVideoConversation = {
  twilioKey: string;
} & VideoConversation &
  AbstractEndedConversation;
export type EndedCallConversation = CallConversation &
  AbstractEndedConversation;

export type EndedConversation =
  | EndedChatConversation
  | EndedVideoConversation
  | EndedCallConversation;

export type UnknownConversation = {
  type: ConversationType.UNKNOWN;
  status: ConversationStatus.NOT_STARTED;
} & AbstractConversation;

export type SomeConversation =
  | OngoingVideoConversation
  | OngoingChatConversation
  | OngoingCallConversation
  | NotStartedVideoConversation
  | NotStartedChatConversation
  | NotStartedCallConversation
  | EndedChatConversation
  | EndedVideoConversation
  | EndedCallConversation
  | UnknownConversation;

export type StartingConversation =
  | StartingChatConversation
  | StartingVideoConversation;

export const isOngoingConversation = (
  someConversation: SomeConversation,
): someConversation is OngoingConversation =>
  someConversation.status === ConversationStatus.STARTED;

export const isEndedConversation = (
  someConversation: SomeConversation,
): someConversation is EndedConversation =>
  someConversation.status === ConversationStatus.ENDED;

export const isOngoingVideoConversation = (
  someConversation: SomeConversation,
): someConversation is OngoingVideoConversation =>
  someConversation.type === ConversationType.VIDEO &&
  someConversation.status === ConversationStatus.STARTED;
export const isOngoingChatConversation = (
  someConversation: SomeConversation,
): someConversation is OngoingChatConversation =>
  someConversation.type === ConversationType.CHAT &&
  someConversation.status === ConversationStatus.STARTED;
export const isNotStartedConversation = (
  someConversation: SomeConversation,
): someConversation is NotStartedConversation =>
  someConversation.status === ConversationStatus.NOT_STARTED;
export const isNotStartedVideoConversation = (
  someConversation: SomeConversation,
): someConversation is NotStartedVideoConversation =>
  someConversation.type === ConversationType.VIDEO &&
  someConversation.status === ConversationStatus.NOT_STARTED;
export const isNotStartedChatConversation = (
  someConversation: SomeConversation,
): someConversation is NotStartedChatConversation =>
  someConversation.type === ConversationType.CHAT &&
  someConversation.status === ConversationStatus.NOT_STARTED;
export const isVideoConversation = (someConversation: SomeConversation) =>
  isOngoingVideoConversation(someConversation) ||
  isNotStartedVideoConversation(someConversation);

export const createMockedOngoingChatConversation = (
  chatId: string,
  conversationId = 'uuid',
): OngoingChatConversation => ({
  chatId: createFromType<ConversationLegacyId>(chatId),
  type: ConversationType.CHAT,
  conversationId: createFromType<UUID>(conversationId),
  status: ConversationStatus.STARTED,
  isProactive: false,
});

export const createMockedNotStartedChatConversation =
  (): NotStartedChatConversation => ({
    type: ConversationType.CHAT,
    status: ConversationStatus.NOT_STARTED,
    isProactive: false,
  });

export const UIVisitorMessageType = {
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  IMAGES_MESSAGE: 'IMAGES_MESSAGE',
  FILE_MESSAGE: 'FILE_MESSAGE',
  ESCALATION_ACCEPTED: 'ESCALATION_ACCEPTED',
  ESCALATION_ENDED: 'ESCALATION_ENDED',
  HUNG_UP: 'HUNG_UP',
  FEEDBACK_SENT: 'FEEDBACK_SENT',
  PENDING_TEXT_MESSAGE: 'PENDING_TEXT_MESSAGE',
} as const;

export const UISystemMessageType = {
  EMAIL_REQUESTED: 'EMAIL_REQUESTED',
  EMAIL_REQUESTED_TEXT: 'EMAIL_REQUESTED_TEXT',
  EMAIL_SET: 'EMAIL_SET',
  EMAIL_CONFIRMED: 'EMAIL_CONFIRMED',
  WAITING_TIME_ESTIMATED: 'WAITING_TIME_ESTIMATED',
  WAITING_TIME_EXCEEDED: 'WAITING_TIME_EXCEEDED',
  UNREAD_MESSAGES_SEPARATOR: 'UNREAD_MESSAGES_SEPARATOR',
} as const;

export const UIOperatorMessageType = {
  TEXT_MESSAGE: 'TEXT_MESSAGE',
  COBROWSING_ASKED_MESSAGE: 'COBROWSING_ASKED_MESSAGE',
  COBROWSING_ANSWERED_MESSAGE: 'COBROWSING_ANSWERED_MESSAGE',
  IMAGES_MESSAGE: 'IMAGES_MESSAGE',
  FILE_MESSAGE: 'FILE_MESSAGE',
  CARD_MESSAGE: 'CARD_MESSAGE',
  CARD_BUNDLE_MESSAGE: 'CARD_BUNDLE_MESSAGE',
  PRODUCT_OFFER_MESSAGE: 'PRODUCT_OFFER_MESSAGE',
  PRODUCT_OFFER_BUNDLE_MESSAGE: 'PRODUCT_OFFER_BUNDLE_MESSAGE',
  QUICK_REPLY_MENU: 'QUICK_REPLY_MENU',
  WELCOME_MESSAGE: 'WELCOME_MESSAGE',
  ADD_TO_CART_MESSAGE: 'ADD_TO_CART_MESSAGE',
  SATISFACTION_MESSAGE: 'SATISFACTION_MESSAGE',
  WAITING_LIST_IS_FULL: 'WAITING_LIST_IS_FULL',
  ESCALATION_INVITATION_SENT: 'ESCALATION_INVITATION_SENT',
  ESCALATION_ENDED: 'ESCALATION_ENDED',
  HUNG_UP: 'HUNG_UP',
  FEEDBACK_REQUESTED: 'FEEDBACK_REQUESTED',
  GDPR_CONSENT_REQUESTED_MESSAGE: 'GDPR_CONSENT_REQUESTED_MESSAGE',
} as const;

export const UIMessageType = {
  ...UISystemMessageType,
  ...UIOperatorMessageType,
  ...UIVisitorMessageType,
};

export enum UIAuthorType {
  OPERATOR = 'OPERATOR',
  VISITOR = 'VISITOR',
  SYSTEM = 'SYSTEM',
  UNKNOWN = 'UNKNOWN',
}

export type UIGenericAuthor = {
  id: string;
  type: UIAuthorType;
};

export type UIAgentAuthor = {
  type: UIAuthorType.OPERATOR;
  avatar: string;
  role: AuthorType;
  nickName: string;
} & UIGenericAuthor;

export type UIVisitorAuthor = {
  type: UIAuthorType.VISITOR;
} & UIGenericAuthor;

export type UISystemAuthor = {
  type: UIAuthorType.SYSTEM;
} & UIGenericAuthor;

export type UIUnknownAuthor = {
  type: UIAuthorType.UNKNOWN;
} & UIGenericAuthor;

export type UIAuthor =
  | UIAgentAuthor
  | UISystemAuthor
  | UIVisitorAuthor
  | UIUnknownAuthor;

export enum UIMessageStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  ERRORED = 'ERRORED',
  GDPR_CONSENT_REQUESTED_PENDING = 'GDPR_CONSENT_REQUESTED_PENDING',
}

export enum UIMessageSource {
  HISTORY = 'HISTORY',
  REAL_TIME = 'REAL_TIME',
}

export type UIMessagesStatuses = { [key: string]: UIMessageStatus };
export type UIMessagesSources = { [key: string]: UIMessageSource };

type UIGenericMessage<TMessageType> = {
  id: string;
  type: TMessageType;
  createdAt: string;
  time: string; //_______________________________________
  status: UIMessageStatus;
  source: UIMessageSource;
  isFullWidth?: boolean;
  isWithoutMargins?: boolean;
};

type UIFeedbackRequestedMessage<TMessageType> = {
  text: string;
} & UIGenericMessage<TMessageType>;

type UITextMessage<TMessageType> = {
  text: string;
} & UIGenericMessage<TMessageType>;

type UIImagesMessage<TMessageType> = {
  images: {
    url: string;
    description: string;
  }[];
} & UIGenericMessage<TMessageType>;

type UIFileMessage<TMessageType> = {
  url: string;
  fileName: string;
  mimeType: string;
} & UIGenericMessage<TMessageType>;

type UIPendingTextMessage<TMessageType> = {
  text: string;
} & UIGenericMessage<TMessageType>;

export type UICard = {
  image?: {
    url: string;
    description: string;
  };
  title?: string;
  text?: string;
  actions: UIAction[];
};

type UICardMessage<TMessageType> = UIGenericMessage<TMessageType> & UICard;

type UICardBundleMessage<TMessageType> = {
  cards: UICard[];
} & UIGenericMessage<TMessageType>;

export type UIProductOffer = {
  image?: {
    url: string;
    description: string;
  };
  name: string;
  price: string;
  offerPrice?: string;
  internalProductId?: string;
  availability?: UIProductOfferAvailabilityStatus;
  description?: string;
  actions: UIAction[];
};

type UIProductOfferMessage<TMessageType> = UIGenericMessage<TMessageType> &
  UIProductOffer;

type UIProductOfferBundleMessage<TMessageType> = {
  productOffers: UIProductOffer[];
} & UIGenericMessage<TMessageType>;

type UIQuickReplyMenuMessage<TMessageType> = {
  message: string;
  choices: string[];
} & UIGenericMessage<TMessageType>;

type UICobrowsingAskedMessage<TMessageType> = {
  alreadyAsked: boolean;
} & UIGenericMessage<TMessageType>;

type UIEmailRequested<TMessageType> = {
  correlationId: UUID;
} & UIGenericMessage<TMessageType>;

export type UIEmailRequestedText<TMessageType> = {
  correlationId: UUID;
  text: string;
} & UIGenericMessage<TMessageType>;

type UIEmailSet<TMessageType> = {
  correlationId: UUID;
} & UIGenericMessage<TMessageType>;

export type UIEmailConfirmed<TMessageType> = {
  correlationId: UUID;
  text: string;
} & UIGenericMessage<TMessageType>;

export type UIWelcomeMessage<TMessageType> = UIGenericMessage<TMessageType>;

export type UIAddToCartMessage<TMessageType> = {
  productName: string;
  success: boolean;
} & UIGenericMessage<TMessageType>;

export type UISatisfactionMessage<TMessageType> =
  {} & UIGenericMessage<TMessageType>;

export type UIWaitingListIsFullMessage<TMessageType> =
  UIGenericMessage<TMessageType>;

export type UIVideoConversationMissedMessage<TMessageType> =
  UIGenericMessage<TMessageType>;

export type UIEscalationInvitationSentMessage<TMessageType> = {
  correlationId: UUID;
  escalationType: EscalationType;
} & UIGenericMessage<TMessageType>;

type UIEscalationAcceptedMessage<TMessageType> = {
  correlationId: UUID;
  escalationType: EscalationType;
} & UIGenericMessage<TMessageType>;

type UIEscalationEndedMessage<TMessageType> = {
  correlationId: UUID;
  escalationType: EscalationType;
  reason: EscalationReason;
} & UIGenericMessage<TMessageType>;

type UIHungupMessage<TMessageType> = {
  correlationId: UUID;
} & UIGenericMessage<TMessageType>;

type UIFeedbackSentMessage<TMessageType> = {
  feedback: GenerativeAIFeedback;
  messageId: UUID;
} & UIGenericMessage<TMessageType>;

export type UIWaitingTimeEstimated<TMessageType> = {
  message: string;
  estimation: WaitingTimeEstimation;
} & UIGenericMessage<TMessageType>;

export type UIWaitingTimeExceeded<TMessageType> = {
  message: string;
} & UIGenericMessage<TMessageType>;

export type UIUnreadMessagesSeparator<TMessageType> = {
  unreadMessages: number;
} & UIGenericMessage<TMessageType>;

export type UIGdprConsentRequestedMessage<TMessageType> =
  UIGenericMessage<TMessageType>;

export type UISystemMessage =
  | UIEmailRequested<typeof UISystemMessageType.EMAIL_REQUESTED>
  | UIEmailRequestedText<typeof UISystemMessageType.EMAIL_REQUESTED_TEXT>
  | UIEmailSet<typeof UISystemMessageType.EMAIL_SET>
  | UIEmailConfirmed<typeof UISystemMessageType.EMAIL_CONFIRMED>
  | UIWaitingTimeEstimated<typeof UISystemMessageType.WAITING_TIME_ESTIMATED>
  | UIWaitingTimeExceeded<typeof UISystemMessageType.WAITING_TIME_EXCEEDED>
  | UIUnreadMessagesSeparator<
      typeof UISystemMessageType.UNREAD_MESSAGES_SEPARATOR
    >;

export type UIOperatorMessage =
  | UIFeedbackRequestedMessage<typeof UIOperatorMessageType.FEEDBACK_REQUESTED>
  | UITextMessage<typeof UIOperatorMessageType.TEXT_MESSAGE>
  | UIImagesMessage<typeof UIOperatorMessageType.IMAGES_MESSAGE>
  | UIFileMessage<typeof UIOperatorMessageType.FILE_MESSAGE>
  | UICardMessage<typeof UIOperatorMessageType.CARD_MESSAGE>
  | UICardBundleMessage<typeof UIOperatorMessageType.CARD_BUNDLE_MESSAGE>
  | UIProductOfferMessage<typeof UIOperatorMessageType.PRODUCT_OFFER_MESSAGE>
  | UIProductOfferBundleMessage<
      typeof UIOperatorMessageType.PRODUCT_OFFER_BUNDLE_MESSAGE
    >
  | UIQuickReplyMenuMessage<typeof UIOperatorMessageType.QUICK_REPLY_MENU>
  | UICobrowsingAskedMessage<
      typeof UIOperatorMessageType.COBROWSING_ASKED_MESSAGE
    >
  | UIGenericMessage<typeof UIOperatorMessageType.COBROWSING_ANSWERED_MESSAGE>
  | UIGenericMessage<typeof UIOperatorMessageType.WELCOME_MESSAGE>
  | UIAddToCartMessage<typeof UIOperatorMessageType.ADD_TO_CART_MESSAGE>
  | UIGenericMessage<typeof UIOperatorMessageType.SATISFACTION_MESSAGE>
  | UIGenericMessage<typeof UIOperatorMessageType.WAITING_LIST_IS_FULL>
  | UIEscalationInvitationSentMessage<
      typeof UIOperatorMessageType.ESCALATION_INVITATION_SENT
    >
  | UIEscalationEndedMessage<typeof UIOperatorMessageType.ESCALATION_ENDED>
  | UIGdprConsentRequestedMessage<
      typeof UIOperatorMessageType.GDPR_CONSENT_REQUESTED_MESSAGE
    >;

export type UIVisitorMessage =
  | UITextMessage<typeof UIVisitorMessageType.TEXT_MESSAGE>
  | UIImagesMessage<typeof UIVisitorMessageType.IMAGES_MESSAGE>
  | UIFileMessage<typeof UIVisitorMessageType.FILE_MESSAGE>
  | UIEscalationAcceptedMessage<typeof UIVisitorMessageType.ESCALATION_ACCEPTED>
  | UIEscalationEndedMessage<typeof UIVisitorMessageType.ESCALATION_ENDED>
  | UIHungupMessage<typeof UIVisitorMessageType.HUNG_UP>
  | UIFeedbackSentMessage<typeof UIVisitorMessageType.FEEDBACK_SENT>
  | UIPendingTextMessage<typeof UIVisitorMessageType.PENDING_TEXT_MESSAGE>;

export type UIMessage = UIOperatorMessage | UISystemMessage | UIVisitorMessage;

export type UIGenericMessageStack = {
  id: string;
  firstCreatedAt: string;
  lastMessageCreatedAt: string;
  author: UIAuthor;
  messages: UIMessage[];
};

export type UIAgentMessageStack = {
  author: UIAgentAuthor;
  isFullWidth?: boolean;
  isWithoutMargins?: boolean;
} & UIGenericMessageStack;

export type UIVisitorMessageStack = {
  author: UIVisitorAuthor;
} & UIGenericMessageStack;

export type UISystemMessageStack = {
  author: UISystemAuthor;
  isFullWidth?: boolean;
} & UIGenericMessageStack;

export type UIUnknownMessageStack = {
  author: UIUnknownAuthor;
} & UIGenericMessageStack;

export type UIMessageStack =
  | UIAgentMessageStack
  | UIVisitorMessageStack
  | UISystemMessageStack
  | UIUnknownMessageStack;

export type UIConversationHistory = {
  id: string;
  createdAt: DateISO;
  messageStacks: UIMessageStack[];
};

export enum UIActionType {
  SELECT = 'SELECT',
  LINK = 'LINK',
  SEE_MORE = 'SEE_MORE',
  GO_TO_SETTING_PANEL = 'GO_TO_SETTING_PANEL',
}

export enum UIProductOfferAvailabilityStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export type UIGenericAction = {
  type: UIActionType;
};

export type UISelectAction = {
  type: UIActionType.SELECT;
  title: string;
} & UIGenericAction;

export type UILinkAction = {
  type: UIActionType.LINK;
  url: string;
  title: string;
  description?: string;
} & UIGenericAction;

export type UISettingsPanelAction = {
  type: UIActionType.GO_TO_SETTING_PANEL;
} & UIGenericAction;

export type UISeeMoreAction = {
  type: UIActionType.SEE_MORE;
  url: string;
} & UIGenericAction;

export type UIAction =
  | UISelectAction
  | UILinkAction
  | UISeeMoreAction
  | UISettingsPanelAction;

export const isUIAgentAuthor = (author: UIAuthor): author is UIAgentAuthor =>
  author.type === UIAuthorType.OPERATOR;

export const isUIVisitorAuthor = (
  author: UIAuthor,
): author is UIVisitorAuthor => author.type === UIAuthorType.VISITOR;

export const isUISystemAuthor = (author: UIAuthor): author is UISystemAuthor =>
  author.type === UIAuthorType.SYSTEM;

export const isUIUnknownAuthor = (
  author: UIAuthor,
): author is UIUnknownAuthor => author.type === UIAuthorType.UNKNOWN;
