import { NotificationTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import { TargetingNotificationAction } from '../../../shared/types/rules';
import createStore, { Store } from '../../../shared/utils/createStore';
import { Configuration, OrchestratorState } from '../types';
import { OrchestratorStoreActions } from './actions';
import { OrchestratorStoreEvents } from './events';
import { ChatboxInitializationParameters } from './types';
import { InputDeviceTypes } from '../../../shared/InputDeviceTypes';

const getNotificationTemplate = (
  { interactionRules, notifications }: Configuration['proactifData'],
  ruleId?: number,
): NotificationTemplate | null => {
  if (!interactionRules?.length || !ruleId) return null;
  const activeRule = interactionRules.find(
    (rule) => String(rule.id) === String(ruleId),
  );
  if (!activeRule) return null;
  return (
    notifications[
      (activeRule.targetingActions?.[0] as TargetingNotificationAction)
        ?.notificationId
    ] || null
  );
};

export const createOrchestratorStore = (
  configuration: Configuration,
): OrchestratorStore => {
  const initialOrchestratorState: OrchestratorState = {
    chatboxInitializationParameters: {} as ChatboxInitializationParameters,
    chatboxInitialized: false,
    chatboxSagasInitialized: false,
    chatboxState: null,
    currentConversationHistoryId: configuration.historyId,
    fullscreen: configuration.tagConfig?.mode === 'embedded',
    isAuthenticated: configuration.isAuthenticated,
    isChatting: configuration.isChatting,
    isDeviceMobile: false,
    isFromMail: configuration.isFromMail,
    isProactiveConversation: false,
    language: configuration.language,
    notificationPosition: configuration.chatbox.notificationPosition,
    notificationTemplate: getNotificationTemplate(
      configuration.proactifData,
      configuration.activeRuleId,
    ),
    prefetchedMessagesCount: 0,
    videoboxState: null,
    isVideoLobbyEnabled: configuration.isVideoLobbyEnabled,
    firstVisitorMessage: null,
    currentInputMode: InputDeviceTypes.mouse,
  };
  const orchestratorStore = createStore(
    initialOrchestratorState,
    'orchestratorStore',
  );
  return orchestratorStore;
};

export type OrchestratorStore = Store<
  OrchestratorState,
  OrchestratorStoreEvents & OrchestratorStoreActions
>;
