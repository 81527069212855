/*_______________________________________________________*/
import { Translations } from '@iadvize/translations-library';
import { WebsiteId, Vuid } from '../../chatbox/app-helpers';
import { NotificationPosition } from './externalTypes';
import { OrchestratorChannel } from './orchestratorStore/channel';
import { DeviceInformation } from '../../shared/DeviceInformationExtractor';
import { ConversationType } from '../../chatbox/livechat-helpers/rfcConversation/Messages';
import {
  DynamicConfig,
  FeatureFlags,
  VisitorProfile,
} from '../../shared/types/dynamicConfig';
import { GetIsClickedUrlHandled } from '../../entry/publicMethods/handleClickedUrl/types';
import { SentryLauncher } from '../../shared/utils/sentry/sentryLauncher';
import { SentryConfig } from '../../shared/utils/sentry/types';
import { TagConfig } from '../../entry/iAdvizeInterface/types';
import { IdzFlags } from '../../shared/types/IdzFlags';
import { ChatboxTemplate } from '../../shared/types/chatboxTemplate';
import { PublicPropertiesStore } from '../../shared/types/publicMethods';
import { CrossEventEmitterType } from '../../entry/crossEventEmitter';
import { OrchestratorStore } from './orchestratorStore/store';
import { DraggableInstance } from '../../shared/draggable/draggable';
import { AddToCartEventEmitterType } from '../../entry/publicMethods/handleAddToCart/handleAddToCart';

export type GlobalParameters = {
  iAdvizeContainer: HTMLElement;
  flags: IdzFlags;
  featureFlags: FeatureFlags;
  satisfactionSurveyEnabled: boolean;
  styles: {
    sandboxStyle: string;
    chatboxV2: string;
    fontIcons: string;
  };
  chatUrl: string;
  conversationApiUrl: string;
  graphqlUrl: string;
  platform: string;
  websiteId: WebsiteId;
  clientId: number;
  vuid: Vuid;
  vProf: VisitorProfile;
  version: string;
  onOrchestratorLoaded: (
    orchestratorChannel: OrchestratorChannel,
    orchestratorStore: OrchestratorStore,
  ) => void;
  getTranslations: () => Translations;
  locale: string;
  anonymizationDelay: number;
  visitor: {
    notificationEmail: string;
    notificationEnabled: boolean;
    device: DeviceInformation;
  };
  chatboxTemplate: ChatboxTemplate;
  notificationPosition: NotificationPosition;
  apiUrls: {
    engagementGDPRConsent: string;
  };
  isFromMail: boolean;
  gdprConsentEnabled: boolean;
  implicitGdprConsentEnabled: boolean;
  isRestoredConversation: boolean;
  isMirroringEnabled: boolean;
  websiteOfficeHours: {
    days: string;
    start_time: string;
    end_time: string;
  } | null;
  websiteTimezoneOffset: number;
  conversationType: ConversationType | null;
  isAuthenticated: boolean;
  staticUrl: string;
  proactifData: DynamicConfig['proactifData'];
  getIsClickedUrlHandled: GetIsClickedUrlHandled;
  sentryLauncher: SentryLauncher;
  sentryConfig: SentryConfig;
  tagConfig?: TagConfig;
  publicPropertiesStore: PublicPropertiesStore;
  addToCartEventEmitter: AddToCartEventEmitterType;
  getHost: () => Window['host'];
  crossEventEmitter: CrossEventEmitterType;
  draggable?: DraggableInstance;
};

export const getGlobalParameters = () => window as any as GlobalParameters;
