import scheduleOnMainJsThread from '../../../lib/scheduleOnMainTread';
import { setStaticUrl } from '../../shared/staticUrl';
import { getConfiguration } from './configurationService';
import { getGlobalParameters } from './globalParametersService';
import storeFactory from './store';

export default async () => {
  const {
    flags,
    featureFlags,
    onOrchestratorLoaded,
    getTranslations,
    chatboxTemplate,
    notificationPosition,
    isFromMail,
    isAuthenticated,
    staticUrl,
    proactifData,
    visitor,
    getIsClickedUrlHandled,
    tagConfig,
    crossEventEmitter,
  } = getGlobalParameters();

  //____________________________
  //_____________________________________________________________
  declare let __webpack_public_path__: string | null;
  //_________________________________________________________________________________________
  __webpack_public_path__ = setStaticUrl(staticUrl);

  const videoLobbyABTestTreshold =
    featureFlags['iadvize.conversations.livechat.video.lobby-ab-test'];
  let { videoLobbyGroupRepartition } = flags;

  if (!videoLobbyGroupRepartition && !!videoLobbyABTestTreshold) {
    videoLobbyGroupRepartition =
      Math.random() < videoLobbyABTestTreshold
        ? 'videoLobbyEnabled'
        : 'videoLobbyDisabled';
  }

  const isVideoLobbyEnabled =
    videoLobbyGroupRepartition === 'videoLobbyEnabled' &&
    featureFlags['iadvize.conversations.livechat.video.lobby'];

  const configuration = getConfiguration(
    flags,
    featureFlags,
    chatboxTemplate.templateAttributes,
    getTranslations,
    notificationPosition,
    isFromMail,
    isAuthenticated,
    proactifData,
    getIsClickedUrlHandled,
    tagConfig,
    isVideoLobbyEnabled,
    videoLobbyGroupRepartition,
  );

  const { orchestratorChannel, orchestratorStore } = storeFactory(
    configuration,
    crossEventEmitter,
  );

  let chatboxInitialized = false;

  const isDeviceMobile = visitor.device.deviceType === 'mobile';
  //________________________________________________
  if (isDeviceMobile || isVideoLobbyEnabled) {
    configuration.chatbox.state = 'REDUCED';
  }

  orchestratorChannel.onInitializeChatbox(() => {
    if (!chatboxInitialized) {
      chatboxInitialized = true;
      import(/*______________________________________*/ './startChatbox').then(
        ({ default: startChatbox }) => {
          scheduleOnMainJsThread(() => {
            startChatbox(configuration, orchestratorChannel, orchestratorStore);
          })();
        },
      );
    }
  });

  orchestratorChannel.onChatboxClosed(() => {
    chatboxInitialized = false;
  });

  scheduleOnMainJsThread(() => {
    onOrchestratorLoaded(orchestratorChannel, orchestratorStore);
  })();
};
