import { Configuration } from './types';
import { createOrchestratorStore } from './orchestratorStore/store';
import createOrchestratorChannel from './orchestratorStore/channel';
import registerFullscreenEffects from './orchestratorStore/effects/fullscreen';
import registerNetworkEffects from './orchestratorStore/effects/network';
import registerMediaContextEffects from './orchestratorStore/effects/mediaContext';
import registerMobileClickOutsideEffects from './orchestratorStore/effects/mobileClickOutside';
import registerReducerEffects from './orchestratorStore/effects/reducer';
import { CrossEventEmitterType } from '../../entry/crossEventEmitter';
import registerTabVisibilityEffects from './orchestratorStore/effects/tabVisibility';

export default function storeFactory(
  configuration: Configuration,
  crossEventEmitter: CrossEventEmitterType,
) {
  const orchestratorStore = createOrchestratorStore(configuration);

  registerFullscreenEffects(orchestratorStore);
  registerNetworkEffects(orchestratorStore);
  registerReducerEffects(orchestratorStore);
  registerMediaContextEffects(orchestratorStore);
  registerTabVisibilityEffects(orchestratorStore);

  if (
    configuration.featureFlags['iadvize.conversations.livechat.smallerChatbox']
  ) {
    registerMobileClickOutsideEffects(orchestratorStore);
  }

  //______________________________________________

  const orchestratorChannel = createOrchestratorChannel(
    orchestratorStore,
    crossEventEmitter,
  );

  return {
    orchestratorStore,
    orchestratorChannel,
  };
}
