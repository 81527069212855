import {
  publicResetChatbox,
  publicSetAuthenticationState,
  publicSetPrefetchedMessages,
} from '../actions';
import {
  publicChatboxOpened,
  publicChatboxReduced,
  publicProactiveMessagesFetched,
  publicVideoboxOpened,
  publicVideoboxReduced,
} from '../events';
import { OrchestratorStore } from '../store';

export default function orchestratorStoreReducer(store: OrchestratorStore) {
  store.on('chatboxInitializationParameters', () => {
    store.dispatch('chatboxInitialized', true);
  });

  store.on(publicSetPrefetchedMessages, ({ prefetchedMessagesCount }) => {
    store.dispatch('isProactiveConversation', true);
    store.dispatch('prefetchedMessagesCount', prefetchedMessagesCount);
  });

  store.on(publicProactiveMessagesFetched, (messages) => {
    store.dispatch(publicSetPrefetchedMessages, {
      prefetchedMessagesCount: messages.length,
    });
  });

  store.on(publicSetAuthenticationState, (authenticationState) => {
    store.dispatch('isAuthenticated', authenticationState !== 'ANONYMOUS');
  });

  store.on(publicChatboxOpened, () => {
    store.dispatch('chatboxState', 'OPEN');
  });

  store.on(publicChatboxReduced, () => {
    store.dispatch('chatboxState', 'REDUCED');
  });

  store.on(publicVideoboxOpened, () => {
    store.dispatch('videoboxState', 'OPEN');
  });

  store.on(publicVideoboxReduced, () => {
    store.dispatch('videoboxState', 'REDUCED');
  });

  store.on(publicResetChatbox, () => {
    store.reset();
  });
}
